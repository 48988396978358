import $ from 'jquery';

if($('.page-template-home-template').length) {
   const imageItems = $('img[data-item]');
   const menuItems = $('a[data-item]');
   
   $(menuItems).each(function(index) {
      $(this).hover(function() {
         const itemId = $(this).attr('data-item');
         const image = $(`img[data-item=${itemId}]`);
         image.toggleClass('active');
      });
   });

}