/* Import SCSS files */
import '../sass/main.scss';

/* Import JS files */

/* Import components here */
import './snippets/header';
import './snippets/home-template';
import './snippets/footer';

