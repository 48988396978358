import $ from 'jquery';

if($('#site-header').length) {
   var scrollTimeout;
   var throttle = 20;
   var prevScroll;
   const siteHeader = $('#site-header');
   const burgerIcon = $('#burger-menu-icon');
   const burgerMenu = $('#burger-nav');
   const burgerMenuItems = $('a', burgerMenu);

   /**
    * Add following 2 states to the navigation:
    * Scroll-state and top-state.
    */
   $(window).scroll(function() {
      if(!scrollTimeout) {
         scrollTimeout = setTimeout(function() {
            var scroll = $(this).scrollTop();
            if(scroll > 30 && !siteHeader.hasClass('scrolling')) {
               siteHeader.addClass('scrolling');
            } else if(scroll < 30 && siteHeader.hasClass('scrolling')) {
               siteHeader.removeClass('scrolling');
            }

            if(prevScroll < scroll && scroll > 30) {
               // hide

               siteHeader.addClass('hidden');
            } else {
               // show
               siteHeader.removeClass('hidden');
            }

            prevScroll = scroll;
            scrollTimeout = null;
         }, throttle);
      }
   });

   /**
    * Burger menu functionality
    */
   burgerIcon.click(function() {
      siteHeader.addClass('scrolling'); // ensure logo size and header background.
      siteHeader.removeClass('hidden'); // ensure header is visible.
      $('body').toggleClass('burger-menu-open'); // ensure body can't scroll.

      burgerIcon.toggleClass('active');
      burgerMenu.toggleClass('active');
   });

   burgerMenuItems.each(function() {
      $(this).on('click', function(e) {
         let menu = $(e.target).siblings('ul');
         if( menu.length ) {
            e.preventDefault();
            menu.toggleClass('active');
         }
      });
   });
    
   // burgerMenuItems.each(function() {
   //    $(this).hover(function() {
   //       $(this).toggleClass('target');
   //       burgerMenuItems.each(function() {
   //          $(this).toggleClass('hover');
   //       })
   //    });
   // });
}